import { DirectoryUser } from 'src/app/data/data.models';
import { AllUsersCheck, CheckResult } from '../integrity.models';

export class CircularReferencesCheck extends AllUsersCheck {
    static checkName = 'CircularReferencesCheck';
    constructor() {
        super('managerId');
    }
    run(users: DirectoryUser[]): CheckResult[] {
        const results: CheckResult[] = [];
        const userMap: Record<string, DirectoryUser> = {};

        for (const user of users) {
            userMap[user.id] = user;
        }

        for (const user of users) {
            const visited = new Set<string>();
            let currentUser: DirectoryUser | undefined = user;

            while (currentUser && currentUser.managerId && !visited.has(currentUser.managerId)) {
                visited.add(currentUser.id);
                currentUser = userMap[currentUser.managerId];
            }

            if (currentUser && visited.has(currentUser.id)) {
                //put all people's names in the chain into message
                const message = [...Array.from(visited), currentUser.id]
                    .map((id) => userMap[id].displayName)
                    .join(' -> ');
                results.push({
                    checkName: 'CircularReferencesCheck',
                    userId: user.id,
                    message: message,
                    checkIndex: -1,
                    isFixed: false,
                    isModified: false
                });
            }
        }

        return results;
    }

    getDisplayName(): string {
        return 'Circular References';
    }

    getDescription(): string {
        return 'Checks for circular references in the manager chain';
    }
}
