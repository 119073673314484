import { Injectable } from '@angular/core';
import { DirectoryUser } from 'src/app/data/data.models';
import { ReportConfig, ReportConfigData } from '../checks/report-config';
import { EmptyValuesCheck } from '../checks/missing-value-check';
import { IntegrityReport } from '../checks/integrity-report';
import { ReportResult } from '../checks/report-result';
import { catchError, concatMap, Observable, of, switchMap, take } from 'rxjs';
import { MissingManagersCheck } from '../checks/missing-manager-check';
import { ReportsToSelfCheck } from '../checks/reports-to-self-check';
import { CircularReferencesCheck } from '../checks/circular-references-check';
import { DuplicateValuesCheck } from '../checks/duplicate-values-check';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { integrityFeature } from '../integrity.reducer';
import { AppDB } from 'src/app/services/db.service';
import { DirectoryUserModification, IntegrityModificationLog } from '../integrity.models';
import { HttpClient } from '@angular/common/http';
import { SettingsServerResponse } from 'src/app/settings/settings.models';

const USERS_URL = 'https://graph.microsoft.com/v1.0/users/';
const USERS_ME_URL = 'https://graph.microsoft.com/v1.0/me';
const INTEGRITY_URL = '/api/integrity';
const INTEGRITY_LOGS_URL = '/api/integrity/logs';
const USER_SET_MANAGER_URL = 'https://graph.microsoft.com/v1.0/users/USER_ID/manager/$ref';
@Injectable()
export class IntegrityService {
    constructor(private store: Store, private db: AppDB, private http: HttpClient) {}

    saveReportConfigData(configData: ReportConfigData): Observable<number> {
        return this.db.saveReportConfig(configData);
    }

    saveUserModifications(modifications: DirectoryUserModification[]) {
        return this.http.post<SettingsServerResponse>(INTEGRITY_URL, modifications);
    }

    saveUserModificationsInDb(modifications: DirectoryUserModification[]): Observable<number> {
        return this.db.clearUserModifications().pipe(switchMap(() => this.db.saveUserModifications(modifications)));
    }

    getReportConfigData(): Observable<ReportConfigData> {
        return this.db.getReportConfig().pipe(
            switchMap((savedConfig: ReportConfigData | null) => {
                if (savedConfig) {
                    return of(savedConfig);
                } else {
                    return this.db.getSavedCategories().pipe(
                        switchMap((categories) => {
                            const config = new ReportConfig(1, 'Report');
                            config.addCheck(new EmptyValuesCheck('firstName'));
                            config.addCheck(new EmptyValuesCheck('lastName'));
                            config.addCheck(new EmptyValuesCheck('jobTitle'));
                            config.addCheck(new EmptyValuesCheck('department'));
                            config.addCheck(new EmptyValuesCheck('country'));
                            config.addCheck(new EmptyValuesCheck('city'));
                            categories.forEach((category) => {
                                if (
                                    config.getChecks().findIndex((check) => check.getField() === category.fieldName) ===
                                    -1
                                ) {
                                    config.addCheck(new EmptyValuesCheck(category.fieldName));
                                }
                            });
                            config.addCheck(new MissingManagersCheck());
                            config.addCheck(new ReportsToSelfCheck());
                            config.addCheck(new CircularReferencesCheck());
                            config.addCheck(new DuplicateValuesCheck('mail'));
                            config.addCheck(new DuplicateValuesCheck('mobilePhone'));
                            return of(config.toJSON());
                        })
                    );
                }
            }),
            catchError((error) => {
                console.error('Failed to get report configuration data:', error);
                // Return an empty configuration in case of an error
                return of({
                    id: 1,
                    name: 'Report Configuration',
                    checks: []
                });
            })
        );
    }

    runReport(users: DirectoryUser[], modifiedUsers: DirectoryUser[]): Observable<ReportResult> {
        return this.store.pipe(
            select(integrityFeature.selectReportConfigData),
            take(1),
            switchMap((configData) => {
                if (!configData) {
                    return of(null);
                }
                const config = ReportConfig.fromJSON(configData);
                const report = new IntegrityReport('Report', config);
                return of(report.run(users, modifiedUsers));
            })
        );
    }

    saveReportResult(report: ReportResult): Observable<number> {
        console.log('saving', report);
        return this.db.saveReportResult(report);
    }

    getReportResult(): Observable<ReportResult> {
        return this.db.getReportResult();
    }

    consentRequest() {
        return this.store.pipe(select(fromRoot.selectUser), take(1)).pipe(
            switchMap((user) => {
                return this.http.patch(USERS_URL + user.localAccountId, {});
            })
        );
    }

    updateUser(modifications: DirectoryUserModification[], userId: string) {
        //format changes
        const payload = modifications.reduce((acc, change) => {
            if (change.fieldName === 'managerId') {
                //
            } else if (change.fieldName === 'lastName') {
                acc['surname'] = change.newValue;
            } else if (change.fieldName === 'firstName') {
                acc['givenName'] = change.newValue;
            } else {
                acc[change.fieldName] = change.newValue;
            }
            return acc;
        }, {});
        const managerChange = modifications.find((change) => change.fieldName === 'managerId');
        const managerPayload = managerChange
            ? {
                  '@odata.id': `https://graph.microsoft.com/v1.0/users/${managerChange.newValue}`
              }
            : null;

        if (managerPayload) {
            return this.http
                .patch(USERS_URL + userId, payload)
                .pipe(concatMap(() => this.http.put(USER_SET_MANAGER_URL.replace('USER_ID', userId), managerPayload)));
        } else {
            return this.http.patch(USERS_URL + userId, payload);
        }
    }

    updateUserProfile(userId: string, payload: Record<string, string>, isSelf: boolean) {
        isSelf = false;
        const url = isSelf ? USERS_ME_URL : USERS_URL + userId;
        const filteredPayload = Object.keys(payload).reduce((acc, key) => {
            if (key === 'managerId') {
                //acc[payload[key]] = payload[key];
            } else if (key === 'lastName') {
                acc['surname'] = payload[key];
            } else if (key === 'firstName') {
                acc['givenName'] = payload[key];
            } else {
                acc[key] = payload[key];
            }
            return acc;
        }, {});
        return this.http.patch(url, filteredPayload);
    }

    getLogs() {
        return this.http.get<IntegrityModificationLog[]>(INTEGRITY_LOGS_URL);
    }

    postLogs(logs: IntegrityModificationLog[]) {
        return this.http.post<boolean>(INTEGRITY_LOGS_URL, logs);
    }
}
