import { IntegrityCheck } from '../integrity.models';
import { CircularReferencesCheck } from './circular-references-check';
import { ContainsDuplicateValuesCheck } from './contains-duplicate-values-check';
import { DuplicateValuesCheck } from './duplicate-values-check';
import { MissingManagersCheck } from './missing-manager-check';
import { EmptyValuesCheck } from './missing-value-check';
import { NoManagerCheck } from './no-manager-check';
import { ReportsToSelfCheck } from './reports-to-self-check';
import { ValidValuesCheck } from './valid-values-check';

export class ReportConfig {
    private checks: IntegrityCheck[] = [];

    constructor(private id: number, private name: string) {}

    addCheck(check: IntegrityCheck) {
        this.checks.push(check);
    }

    getChecks(): IntegrityCheck[] {
        return this.checks;
    }

    toJSON(): ReportConfigData {
        return {
            id: this.id,
            name: this.name,
            checks: this.checks.map((check) => {
                const checkName = (check.constructor as typeof IntegrityCheck).checkName as CheckName;
                const args = this.getCheckArgs(check);
                return { checkName, args };
            })
        };
    }

    static fromJSON(data: ReportConfigData): ReportConfig {
        const config = new ReportConfig(data.id, data.name);
        data.checks.forEach((checkConfig) => {
            const CheckClass = ReportConfig.getCheckClass(checkConfig.checkName);
            if (CheckClass) {
                const checkInstance = new CheckClass(...checkConfig.args);
                config.addCheck(checkInstance);
            }
        });
        return config;
    }

    private getCheckArgs(check: IntegrityCheck): string[] {
        const field = check.getField();
        let result = field ? [field] : [];
        if (check instanceof ValidValuesCheck) {
            result = result.concat(check.validValues);
        }
        return field ? [field] : [];
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    static getCheckClass(checkName: string): new (...args: any[]) => IntegrityCheck | null {
        const checkClasses: Record<string, new (...args: any[]) => IntegrityCheck> = {
            //InvalidEmailsCheck,
            EmptyValuesCheck,
            // CustomCheck,
            ContainsDuplicateValuesCheck,
            DuplicateValuesCheck,
            CircularReferencesCheck,
            NoManagerCheck,
            MissingManagersCheck,
            ReportsToSelfCheck,
            ValidValuesCheck
            // AIIntegrityCheck
        };
        return checkClasses[checkName] || null;
    }
}

export interface ReportConfigData {
    id: number;
    name: string;
    checks: ReportCheckConfig[];
}

export interface ReportCheckConfig {
    checkName: CheckName;
    args: any[];
}

export type CheckName =
    | 'EmptyValuesCheck'
    | 'DuplicateValuesCheck'
    | 'ContainsDuplicateValuesCheck'
    | 'CircularReferencesCheck'
    | 'NoManagerCheck'
    | 'MissingManagersCheck'
    | 'ReportsToSelfCheck'
    | 'ValidValuesCheck';

export const CheckList = [
    'EmptyValuesCheck',
    'ContainsDuplicateValuesCheck',
    'DuplicateValuesCheck',
    'CircularReferencesCheck',
    'NoManagerCheck',
    'MissingManagersCheck',
    'ReportsToSelfCheck',
    'ValidValuesCheck'
];

export const CheckDisplayNames: { [key in CheckName]: string } = {
    EmptyValuesCheck: 'Empty Values',
    DuplicateValuesCheck: 'Duplicate Values',
    ContainsDuplicateValuesCheck: 'Contains Duplicate Values',
    CircularReferencesCheck: 'Circular References',
    NoManagerCheck: 'No Manager',
    MissingManagersCheck: 'Missing Managers',
    ReportsToSelfCheck: 'Reports to Self',
    ValidValuesCheck: 'Valid Values'
};

export const ChecksWithoutArgs = [
    'MissingManagersCheck',
    'ReportsToSelfCheck',
    'CircularReferencesCheck',
    'NoManagerCheck'
];
