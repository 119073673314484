import { DirectoryUser } from 'src/app/data/data.models';
import { AllUsersCheck, CheckResult } from '../integrity.models';

export class MissingManagersCheck extends AllUsersCheck {
    static checkName = 'MissingManagersCheck';
    run(users: DirectoryUser[]): CheckResult[] {
        const results: CheckResult[] = [];
        const userIds = new Set(users.map((user) => user.id));

        for (const user of users) {
            if (user.managerId && !userIds.has(user.managerId)) {
                results.push({
                    checkName: 'MissingManagersCheck',
                    userId: user.id,
                    message: 'Manager not found in data',
                    checkIndex: -1,
                    isFixed: false,
                    isModified: false
                });
            }
        }

        return results;
    }

    getDisplayName(): string {
        return 'Missing Managers';
    }

    getDescription(): string {
        return 'Check if a user has a manager that is not in the data';
    }
}
